import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import { getFirebase } from 'react-redux-firebase';
import Nav from '../nav/nav';
import Header from '../header/header';

const { Content, Footer } = Layout;

const AdminLayout = ({
  component: Component,
  ...rest
}) => {
  const firebase = getFirebase();
  const auth = firebase.auth();
  const authenticated = auth.currentUser;

  if (!authenticated) {
    return <Redirect path="*" to="/auth" />;
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout style={{ minHeight: '100vh' }}>
          <Nav />
          <Layout>
            <Header />
            <Content style={{ margin: '0 16px' }}>
              <Component {...matchProps} />
            </Content>
            <Footer style={{ textAlign: 'center' }}>
              <a href="https://lizard.global">LIZARD.GLOBAL &copy; 2019</a>
            </Footer>
          </Layout>
        </Layout>
      )}
    />
  );
};

export default AdminLayout;
