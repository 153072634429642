import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import { compose } from 'redux';

const DefaultLayout = ({
  component: Component,
  ...rest
}) => {
  const currentCity = rest.computedMatch.params.city.toLowerCase();

  let url = window.location;
  let params = new URLSearchParams(url.search);
  const companyName = params.get('company_name');

  if (!window.location.href.includes('/back')) {
    let url = (window.location !== window.parent.location)
      ? new URL(document.referrer)
      : new URL(document.location.href);
    let params = new URLSearchParams(url.search);
    const objectID = params.get('jobid');
    if (objectID !== undefined && objectID !== null) {
      rest.history.push(`/${currentCity}/jobOverview/?jobid=${objectID}`);
    }
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout className="session">
          <Component {...matchProps} currentCity={currentCity} companyName={companyName} />
        </Layout>
      )}
    />
  );
};

const enhanced = compose(
  withRouter,
);

export default enhanced(DefaultLayout);
