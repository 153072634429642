import DefaultLayout from './DefaultLayout';
import AdminLayout from './AdminLayout';
import LoginLayout from './LoginLayout';
import JobOfferLayout from './JobOfferLayout';
import JobOverviewLayout from './JobOverviewLayout';

export default {
  DefaultLayout,
  AdminLayout,
  LoginLayout,
  JobOfferLayout,
  JobOverviewLayout
}
