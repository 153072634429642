import React, { Component } from 'react';
import { connect } from 'react-redux';

import './topBar.css';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import * as R from 'ramda';
import { Dropdown, Icon, Menu, Row, Col } from 'antd';
import history from '../../utils/history';
import firebase from '../../firebase';

// eslint-disable-next-line react/prefer-stateless-function
const menu = (
  <Menu>
    <Menu.Item key="0">
      <div onClick={()=>{
        history.push('/job-offer');
      }}>
        Add Job Posting
      </div>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="1">
      <div onClick={()=>{
        firebase.auth().signOut().then(()=>{
          window.location.href = "/login";
        });
      }}>
        Logout
      </div>
    </Menu.Item>
  </Menu>
);

// eslint-disable-next-line react/prefer-stateless-function
export class TopBar extends Component {
  render() {
    const { user } = this.props;
    console.log('userRole: ', user);

    return (
      <Row
        className="header-cta-bar"
        type={{ sm: 'block', md: 'flex' }}
        // gutter={{ sm: 13.09, md: 30 }}
      >
        <Col xs={0} sm={0} md={{ span: 16, offset: 1 }} lg={{ span: 12, offset: 4 }} xl={{ offset: 6, span: 9 }} className="header-cta-copy">Our <strong>Year Progam 2019</strong> is online, have a look at what topics we will focus on! </Col>
        <Col xs={0} sm={0} md={6} lg={4} xl={3}>
          <button className="header-cta-button" type="button">Check it out!</button>
        </Col>
        <Col offset={2} xs={20} sm={20} md={0} lg={0}>
          <button className="header-cta-button" type="button">Check it out our Year Program 2019!</button>
        </Col>
        {user && user.role === 'user' ? (
          <div style={{position:'absolute', right:'20px'}}>
            <Dropdown overlay={menu}>
              <a className="ant-dropdown-link" href="#">
                { user.firstName + ' ' + user.lastName } <Icon type="down" />
              </a>
            </Dropdown>
          </div>
        ) : null
        }
      </Row>
    );
  }
}

const enhanced = compose(
  firestoreConnect((props, state) => {
    if (R.pathOr(null, ['currentUser', 'uid'], state.firebase.auth())){
      return [
        {
          collection: '/users',
          doc: state.firebase.auth().currentUser.uid,
          storeAs: 'user_profile',
        },
      ];
    } else {
      return [];
    }
  }),
  connect((state, props) => {
    const { data } = state.firestore;
    const users = R.pathOr({}, ['user_profile'], data);

    return {
      user: users
    };
  }),
);

export default enhanced(TopBar);
