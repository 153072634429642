import './JobOfferLayout.css';
import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Col, Icon, Layout, Row } from 'antd';
import { getFirebase } from 'react-redux-firebase';
import TopBar from '../topBar/topBar';
import NavBar from '../navBar/navBar';

const JobOfferLayout = ({
  component: Component,
  ...rest
}) => {
  const firebase = getFirebase();
  const auth = firebase.auth();
  const authenticated = auth.currentUser;

  // console.log({authenticated})
  const currentCity = rest.computedMatch.params.city.toLowerCase();

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout className="session">
        {/*
          <TopBar />
          <NavBar />
        */}
          <Row className="jo-container">
            <Col style={{ maxWidth: '920px', width: '100%', position: 'absolute', top: 20, right: 0, left: 0, margin: '0 auto', display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ cursor: 'pointer' }} onClick={()=>{
                // window.location.href = "/home";
                matchProps.history.push(`/${currentCity}/home`);
              }}>
                Back to Talent Portal
              </div>
              {authenticated ?
                <div style={{ cursor: 'pointer' }} onClick={()=>{
                  firebase.auth().signOut().then(()=>{
                    // window.location.href = "/login";
                    matchProps.history.push(`/${currentCity}/login`);
                  });
                }}>
                  LOG OUT
                </div> : <div/>
              }
            </Col>
            <Col>
              <Row>
                <Col>
                  <Icon type="star" className="jo-star-icon"/>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h1 className="jo-title">{rest.title}</h1>
                </Col>
              </Row>
              <Component {...matchProps} currentCity={currentCity} isAuthenticate={authenticated}/>
            </Col>
          </Row>
        </Layout>
      )}
    />
  );
};

const enhanced = compose(
  withRouter,
);

export default enhanced(JobOfferLayout);
