import Loadable from 'react-loadable';
import Loading from './components/common-ui/loading';

const getComponent = (component) => (
  Loadable({
    loader: () => import(`./containers/${component}/${component}`),
    loading: Loading
  })
);

export default [
  {
    key: 'landingPage',
    label: '',
    icon: '',
    layout: 'DefaultLayout',
    exact: false,
    path: '/:city/home',
    private: true,
    component: getComponent('landingPage'),
    menu: false,
  },
  {
    key: 'jobOverview',
    label: '',
    icon: '',
    layout: 'JobOverviewLayout',
    exact: false,
    path: '/:city/jobOverview',
    private: true,
    component: getComponent('jobOverview'),
    menu: false,
  },
  {
    key: 'auth',
    label: '',
    icon: '',
    layout: 'LoginLayout',
    exact: false,
    path: '/auth',
    private: false,
    component: getComponent('auth'),
    menu: false,
  },
  {
    key: 'inviteduser',
    label: '',
    icon: '',
    layout: 'LoginLayout',
    exact: false,
    path: '/inviteduser/:id',
    private: false,
    component: getComponent('inviteduser'),
    menu: false,
  },
  {
    key: 'users',
    label: 'Admins',
    icon: 'user',
    layout: 'AdminLayout',
    exact: true,
    path: '/admin/users',
    private: true,
    component: getComponent('users'),
    menu: true,
    roles: ['admin', 'superadmin'],
  },
  {
    key: 'jobs',
    label: 'Jobs',
    icon: 'profile',
    layout: 'AdminLayout',
    exact: true,
    path: '/admin/jobs',
    private: true,
    component: getComponent('jobs'),
    menu: true,
    roles: ['admin', 'superadmin'],
  },
  {
    key: 'employment',
    label: 'Employment',
    icon: 'appstore',
    layout: 'AdminLayout',
    exact: true,
    path: '/admin/employment',
    private: true,
    component: getComponent('employment'),
    menu: true,
    roles: ['superadmin'],
  },
  {
    key: 'industries',
    label: 'Industries',
    icon: 'appstore',
    layout: 'AdminLayout',
    exact: true,
    path: '/admin/industries',
    private: true,
    component: getComponent('industries'),
    menu: true,
    roles: ['superadmin'],
  },
  {
    key: 'functions',
    label: 'Functions',
    icon: 'appstore',
    layout: 'AdminLayout',
    exact: true,
    path: '/admin/functions',
    private: true,
    component: getComponent('functions'),
    menu: true,
    roles: ['superadmin'],
  },
  {
    key: 'cities',
    label: 'Cities',
    icon: 'star-o',
    layout: 'AdminLayout',
    exact: true,
    path: '/admin/cities',
    private: true,
    component: getComponent('cities'),
    menu: true,
    roles: ['superadmin'],
  },
  {
    key: 'jobOffer',
    label: '',
    icon: '',
    layout: 'JobOfferLayout',
    exact: true,
    path: '/:city/job-offer',
    private: true,
    component: getComponent('jobOffer'),
    menu: false,
    title: 'Add job posting to the Talent Portal!'
  },
  {
    key: 'register',
    label: '',
    icon: '',
    layout: 'JobOfferLayout',
    exact: true,
    path: '/:city/register',
    private: true,
    component: getComponent('register'),
    menu: false,
    title: 'Register to the Talent Portal!'
  },
  {
    key: 'login',
    label: '',
    icon: '',
    layout: 'JobOfferLayout',
    exact: true,
    path: '/:city/login',
    private: true,
    component: getComponent('login'),
    menu: false,
    title: 'Login to the Talent Portal!'
  }
];
