import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import { getFirebase } from 'react-redux-firebase';

const { Header, Content, Footer } = Layout;

const JobOverviewLayout = ({
  component: Component,
  ...rest
}) => {
  // const firebase = getFirebase();
  // const auth = firebase.auth();
  // const authenticated = auth.currentUser;

  // if (authenticated) {
  //   return (<Redirect path="*" to="/admin/users"/>);
  // }
  // console.log('JobOverviewLayout');
  const currentCity = rest.computedMatch.params.city.toLowerCase();
  // console.log({rest})

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout style={{ minHeight: '100vh' }}>
          <Layout>
            <Header style={{ background: 'rgba(0,0,0,0)' }}>
              <div className="colorPrimary" style={{ cursor: 'pointer' }} onClick={() => {
                // console.log(window.location !== window.parent.location);
                // console.log('document.referrer', document.referrer);
                // console.log('document.location.href', document.location.href);
                let url = (window.location !== window.parent.location)
                  ? document.referrer + '/back'
                  : document.location.href.substring(0, document.location.href.lastIndexOf('/')).replace("/jobOverview", "/home");
                // window.location.href = url;
                // return (<Redirect path="*" to={url} />);
                // console.log({matchProps})
                matchProps.history.push(`/${currentCity}/home/back`);
              }}>
                Back to Talent Portal
              </div>
            </Header>
            <Content style={{ margin: '0 16px' }}>
              <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
                <Component {...matchProps} currentCity={currentCity}/>
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
              Lizard Apps &copy; 2019
            </Footer>
          </Layout>
        </Layout>
      )}/>
  );
};

const enhanced = compose(
  withRouter,
  firestoreConnect(['/cities']),
  connect(),
);

export default enhanced(JobOverviewLayout);
