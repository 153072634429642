import './nav.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Link, withRouter } from 'react-router-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Layout, Menu, Icon } from 'antd';
import routes from '../../routes';

const { Sider } = Layout

export class Nav extends Component {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  }

  render() {
    const { location, user_profile } = this.props;

    return (
      <Sider
        style={{ minHeight: '100vh' }}
        breakpoint="lg"
        collapsedWidth="0"
        theme="dark"
        className="sider"
      >
        <ReactCSSTransitionGroup
          transitionName="example"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
        >
          {!this.state.collapsed && <div key="logo"></div>}
        </ReactCSSTransitionGroup>
        <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
          {routes.filter(route => route.menu === true && route.roles.includes(user_profile.role)).map(route => (
            <Menu.Item key={route.path}>
              <Link to={route.path}>
                <Icon type={route.icon} />
                <span className="nav-text">{route.label}</span>
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
    );
  };
}

// export default withRouter(Nav);

export default compose(
  firestoreConnect(),
  connect(state => {
    const user_profile = state.firebase.profile;

    return {
      user_profile,
    };
  }),
)(withRouter(Nav));
