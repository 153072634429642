import './components/common.css';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as R from 'ramda';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import layouts from './components/layouts';
import routes from './routes';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .bgColorPrimary,
  ::selection,
  .btnAddJob,
  .sortCircle,
  .load-more,
  .ant-btn-primary,
  .ant-calendar-selected-date .ant-calendar-date:hover,
  .ant-calendar-selected-date .ant-calendar-date,
  .ant-calendar-date:active,
  .ant-calendar-selected-date .ant-calendar-date,
  .ant-checkbox-checked .ant-checkbox-inner
  {
    background-color: ${p => p.primaryColor} !important;
  }

  .colorPrimary,
  .jo-links,
  .see-more,
  .communitySort,
  .ant-btn-default:hover,
  .ant-btn-default:focus,
  .ant-btn:hover,
  .ant-btn:focus
  {
    color: ${p => p.primaryColor} !important;
  }

  .borderColorPrimary,
  .communityCardMain_border,
  .location-search-input,
  .jobOverviewModalFeatured>.ant-modal-content>.ant-modal-body,
  .ant-btn-primary,
  .ant-tag,
  .ant-btn-default:hover,
  .ant-btn-default:focus,
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-input,
  .ant-input:hover,
  .ant-input:focus,
  .ant-select-selection,
  .ant-select-selection:hover,
  .ant-select-selection:focus,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked:after,
  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-inner,
  .bf-container,
  .ant-input-group-addon,
  .searchInputContainer
  {
    border-color: ${p => p.primaryColor} !important;
  }

  .ant-btn-primary:hover,
  .ant-btn-primary:focus
  {
    color: white !important;
  }

  .ant-select-dropdown-menu-item-active
  {
    background-color: ${p => (p.primaryColor+'33')} !important;
  }

  .ant-input:focus,
  .ant-select-open .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active
  {
    box-shadow: 0 0 0 2px ${p => (p.primaryColor+'33')} !important;
  }

  .privacy-box,
  .ant-select-dropdown-menu-item:hover,
  .ant-select-dropdown-menu-item:active,
  .ant-calendar-date:hover
  {
    background-color: ${p => (p.primaryColor+'0C')} !important;
  }

  .header-background,
  .header-background-sticky
  {
    background-image: url(${p => p.headerBackground});
  }

  .ant-tag
  {
    background-color: white;
    pointer-events: none;
  }

  .ant-tag-has-color
  {
    color: rgba(0, 0, 0, 0.65) !important;
  }
`;

class App extends React.PureComponent {
  getRoute = (route) => {
    const Layout = layouts[route.layout] || layouts.DefaultLayout;
    return <Layout {...route} />;
  }

  render() {
    const { currentCity } = this.props;

    return (
      <div className="app">
        <GlobalStyle
          primaryColor={(currentCity && currentCity.hex_color_code) ? currentCity.hex_color_code : "#5d5e5e"}
          headerBackground={(currentCity && currentCity.background_image) ? currentCity.background_image : ''}
        />
        <BrowserRouter>
          <Switch>
            {routes.map(route => this.getRoute(route))}
            <Route exact path="/linkedin" component={LinkedInPopUp} />
            <Redirect path="/:city" to="/:city/home"/>
            <Redirect path="*" to="/:city/home"/>
          </Switch>
        </BrowserRouter>
      </div>
    );
  };
}

const enhanced = compose(
  connect((state, props) => {
    const { data } = state.firestore;
    const cities = R.pathOr({}, ['cities'], data);

    const citySlug = window.location.pathname.split("/")[1].toLowerCase();

    let currentCityKey = Object.keys(cities).filter((key) => {
      return cities[key].slug && cities[key].slug.toLowerCase() === citySlug;
    });
    let currentCity = cities[currentCityKey];

    return {
      cities,
      currentCity: currentCity || {},
    };
  }),
);

export default enhanced(App);
