import './header.css';
import React, { Component } from "react";
import { Layout, Menu, Icon } from 'antd';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { signout } from '../../containers/auth/actions';
import { withRouter } from 'react-router-dom';

const { Header: AntHeader } = Layout;
const SubMenu = Menu.SubMenu;

export class Header extends Component {
  render() {
    const { signout, auth } = this.props;
    const authenticated = auth.isLoaded && !auth.isEmpty;

    return (
      <AntHeader style={{
        background: '#f0f2f5',
        padding: 0,
        height: 48,
      }}>
        <Menu
          onClick={this.handleClick}
          mode="horizontal"
          style={{ background: '#f0f2f5' }}
        >
          {authenticated &&
            <SubMenu style={{float: 'right'}} title={<span>{auth.email} <Icon type="setting" /></span>}>
              {/* <Menu.Item key="setting:1">Profile</Menu.Item> */}
              {/* <Menu.Item key="setting:2">Settings</Menu.Item> */}
              {/* <Menu.Divider /> */}
              <Menu.Item key="settings:3">
                <div onClick={signout}>Logout</div>
              </Menu.Item>
            </SubMenu>
          }
        </Menu>
      </AntHeader>
    )
  }
}

// export default connect(null, null)(Header)

const mapState = state => ({auth: state.firebase.auth, pages: state.firestore.ordered.pages})

const actions = {
  signout
}

const query = props => [
  {
    collection: 'users',
    doc: props.auth.uid || 'guest',
    subcollections: [
      {
        collection: 'pages'
      }
    ],
    storeAs: 'pages'
  }
]

export default withRouter(connect(mapState, actions)(firestoreConnect(query)(Header)))
