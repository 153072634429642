import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import { getFirebase } from 'react-redux-firebase';

const { Content, Footer } = Layout;

const LoginLayout = ({
  component: Component,
  ...rest
}) => {
  const firebase = getFirebase();
  const auth = firebase.auth();
  const authenticated = auth.currentUser;

  if (authenticated) {
    return (<Redirect path="*" to="/admin/users"/>);
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout style={{ minHeight: '100vh' }}>
          <Layout>
            <Content style={{ margin: '0 16px' }}>
              <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
                <Component {...matchProps}/>
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
              Lizard Apps &copy; 2019
            </Footer>
          </Layout>
        </Layout>
      )}/>
  );
};

export default LoginLayout;
