import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyAeNRtnwLTlkwKDLkCeGGj16nAfNRli9GA',
  authDomain: 'venture-cafe.firebaseapp.com',
  databaseURL: 'https://venture-cafe.firebaseio.com',
  projectId: 'venture-cafe',
  storageBucket: 'venture-cafe.appspot.com',
  messagingSenderId: '202209581692',
};

// const firebaseConfig = {
//   apiKey: 'AIzaSyBMVS6_JsUdH24JuFk7oIH8iOk491DXfOk',
//   authDomain: 'venture-cafe-staging-4a93f.firebaseapp.com',
//   databaseURL: 'https://venture-cafe-staging-4a93f.firebaseio.com',
//   projectId: 'venture-cafe-staging-4a93f',
//   storageBucket: 'venture-cafe-staging-4a93f.appspot.com',
//   messagingSenderId: '782015847144',
// };

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const settings = { timestampsInSnapshots: true };
firestore.settings(settings);

export default firebase;
