import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Drawer } from 'antd';
import { FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './navBar.css';

const logo = 'https://venturecaferotterdam.org/wp-content/uploads/2016/10/VC_Rotterdam-Full-Color-LogoTagline-Horizontal-500.png';

// eslint-disable-next-line react/prefer-stateless-function
export class NavBar extends Component {
  state = {
    drawerVisible: false
  };

  showDrawer = () => {
    this.setState({
      drawerVisible: true,
    });
  };

  onCloseDrawer = () => {
    this.setState({
      drawerVisible: false,
    });
  };

  render() {
    // const useless = null;
    // const moreUseless = null;

    return (
      <div className="navbar-container">
        <Row
          style={{ maxWidth: '1120px', height: '100%', width: '100%' }}
          // gutter={{ sm: 13.09, md: 30 }}
        >
          <Col xs={{ offset: 6, span: 12 }} sm={{ offset: 6, span: 12 }} md={{ offset: 0, span: 5 }} style={{ height: '100%' }}>
            <button className="navbar-logo" type="button">
              <img src={logo} alt="logo" width="190"/>
            </button>
          </Col>
          <Col xs={0} sm={0} md={{ offset: 1, span: 3 }} style={{ height: '100%' }}>
            <button className="navbar-item" type="button" style={{ height: '100%', outline: 'none', cursor: 'pointer' }}>Thursday Gatherings</button>
          </Col>
          <Col xs={0} sm={0} md={3} style={{ height: '100%' }}>
            <button className="navbar-item" type="button" style={{ height: '100%', outline: 'none', cursor: 'pointer' }}>International</button>
          </Col>
          <Col xs={0} sm={0} md={3} style={{ height: '100%' }}>
            <button  className="navbar-item" type="button" style={{ height: '100%', outline: 'none', cursor: 'pointer' }}>Community</button>
          </Col>
          <Col xs={0} sm={0} md={3} style={{ height: '100%' }}>
            <Link to={`/home`} style={{ height: '100%', outline: 'none', cursor: 'pointer' }}>
              <button className="navbar-item" type="button" style={{ outline: 'none', cursor: 'pointer' }}>Talent Portal</button>
            </Link>
          </Col>
          <Col xs={0} sm={0} md={3} style={{ height: '100%' }}>
            <button className="navbar-item" type="button" style={{ height: '100%', outline: 'none', cursor: 'pointer' }}>Partners</button>
          </Col>
          <Col xs={0} sm={0} md={3} style={{ height: '100%' }}>
            <button className="navbar-item" type="button" style={{ height: '100%', outline: 'none', cursor: 'pointer' }}>About Us</button>
          </Col>
          <Col xs={{ offset: 3, span: 2 }} sm={{ offset: 0, span: 0 }} md={0} lg={0} className="navbar-menu">
            <button type="button" className="navbar-menu" onClick={() => this.showDrawer()}>
              <FaBars style={{ height: '25px', width: '25px', color: '#000000' }} />
            </button>
          </Col>
        </Row>
        <Drawer
          // title="Basic Drawer"
          placement="right"
          closable={false}
          onClose={this.onCloseDrawer}
          visible={this.state.drawerVisible}
        >
          <button className="navbar-logo" type="button" style={{ marginBottom: '20px' }}>
            <img src={logo} alt="logo" width="190"/>
          </button>
          <button className="navbar-item-drawer" type="button">Thursday Gatherings</button>
          <button className="navbar-item-drawer" type="button">International</button>
          <button  className="navbar-item-drawer" type="button">Community</button>
          <Link to={`/home`} style={{ height: '100%', outline: 'none', cursor: 'pointer' }}>
            <button className="navbar-item-drawer" type="button">Talent Portal</button>
          </Link>
          <button className="navbar-item-drawer" type="button">Partners</button>
          <button className="navbar-item-drawer" type="button">About Us</button>
        </Drawer>
      </div>
    );
  }
}

export default connect(null, null)(NavBar);
